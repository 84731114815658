
import {
  ACTION_GET_HOT_NEWS,
  ACTION_GET_INFO_CARPLA,
  ACTION_GET_SALE_POLICIES,
  ACTION_SIGN_IN_EMAIL
} from "@/utils/store.action"
import Logo from '@/components/Logo'
import config from "@/utils/config";
import RudderStackHandler from "@/mixins/RudderStackHandler";
import CleverTapHandler from "@/mixins/CleverTapHandler";
import FooterCollapseItem from "~/layouts/FooterCollapseItem.vue";
import fbIcon from "../assets/imgs/footer_new/fb.svg";
import ttIcon from "../assets/imgs/footer_new/tt.svg";
import ytIcon from "../assets/imgs/footer_new/yt.svg";
import error from "./error.vue";
import MoreFooterPartItem from "./MoreFooterPartItem.vue";

export default {
  computed: {
    error() {
      return error
    }
  },
  components: {FooterCollapseItem, Logo, MoreFooterPartItem},
  mixins: [RudderStackHandler, CleverTapHandler],
  data() {
    return {
      allowDCMA: config.allowDCMA,
      fbIcon,
      ttIcon,
      ytIcon,
      salesPoliciesList: [],
      hotNews: [],
      emailUser: '',
      showDialog: false,
      success: true,
      hcmContact: {},
      dnContact: {},
      hnContact: {},
      contactStatic: {
        email: 'hotro@carpla.vn',
        hotline: '0825.355.355',
        mailto: "mailto:hotro@carpla.vn",
        tel: "tel:0825355355"
      },
      isOpenA: false,
      errorMsg: ''
    }
  },
  created() {
    this.getCarplaContact()
  },
  mounted() {
    // this.getHotNews()
    // this.getSalesPoliciesList()
  },
  methods: {
    async getHotNews() {
      this.hotNews = await this.$store.dispatch(ACTION_GET_HOT_NEWS)
      this.hotNews = (this.hotNews || []).slice(0, 6)
    },
    async getCarplaContact() {
      // const res = await this.$store.dispatch(ACTION_GET_INFO_CARPLA)
      // if (res && res.hcm) {
      //   this.hcmContact = res.hcm
      //   this.dnContact = res.dn
      //   this.hnContact = res.hn
      // }
    },
    // async getSalesPoliciesList() {
    //   const {data} = await this.$store.dispatch(ACTION_GET_SALE_POLICIES)
    //   if (data) {
    //     this.salesPoliciesList = data
    //   }
    // },
    appDownload(app_name, value) {
      this.clevertapTrack(config.eventTypeTracking.button_downloadapp_clicked, {
        app_id: app_name,
        button_clicked: 'Click download app',
        screen_name: 'footer'
      })
      this.rudderTrack(config.newEventName.outbound_link_clicked, {
        domain: value,
        value,
        page_url: window.location.href,
      })
    },
    clickLinkTracker(link_id, btnName) {
      this.clevertapTrack(config.eventTypeTracking.button_link_clicked, {
        link_id: link_id,
        button_clicked: btnName,
        screen_name: 'footer'
      })
    },
    clickLinkTrack(value) {
      this.rudderTrack(config.newEventName.outbound_link_clicked, {
        domain: value,
        value,
        page_url: window.location.href,
      })
    },
    clickLinkTrackCarplaBlock() {
      this.rudderPageCustom({
        page_url: 'https://carpla.vn/blog',
        path: '/blog',
        page_title: 'Carpla Blog',
      })
    },
    clickLinkTrackCarplaBH() {
      this.rudderTrack(config.newEventName.outbound_link_clicked, {
        domain: 'https://baohiemtasco.com/san-pham/bao-hiem-o-to-xe-may',
        value: 'https://baohiemtasco.com/san-pham/bao-hiem-o-to-xe-may',
        page_url: window.location.href,
      })
      this.rudderPageCustom({
        page_url: 'https://baohiemtasco.com/san-pham/bao-hiem-o-to-xe-may',
        path: '/bao-hiem-xe',
        page_title: 'Carpla Bảo Hiểm',
      })
    },
    checkLoading(invalid) {
      return !!invalid;
    },
    checkMail() {
      const regex = new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g)
      if (regex.test(this.emailUser)) {
        this.errorMsg = ''
      } else {
        this.errorMsg = 'Email có định dạng không đúng'
      }
    },
    clickHotline(value) {
      this.rudderTrack(config.newEventName.contact_link_clicked, {
        type: 'phone',
        value: value.replace('tel:', ''),
        page_url: window.location.href,
        position: 'footer'
      })
    },
    async SignInEmail() {
      const regex = new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g)
      if (regex.test(this.emailUser)) {
        this.errorMsg = ''
        const data = await this.$store.dispatch(ACTION_SIGN_IN_EMAIL, {
          email: this.emailUser
        })
        const emailTrack = this.emailUser
        if (data.email) {
          this.rudderTrack(config.newEventName.subscriber_registered, {
            page_url: window.location.href,
            email: this.emailUser,
            status: 'pass'
          })
          this.emailUser = ''
          this.$nextTick(() => {
            this.$refs.emailForm.reset()
          });
          this.success = true
        } else {
          this.success = false
          this.rudderTrack(config.newEventName.subscriber_registered, {
            page_url: window.location.href,
            status: 'fail',
            email: this.emailUser,
            error_message: 'Email có định dạng không đúng'
          })
        }
        this.clevertapTrack(config.eventTypeTracking.news_form_filled, {
          email: emailTrack,
          button_clicked: 'Đăng ký ngay',
          screen_name: 'footer',
          status: this.success ? 'succeed' : 'falure'
        })
        this.showDialog = true
      } else {
        this.errorMsg = 'Email có định dạng không đúng'
      }

    },
    onShowDialog() {
      if (this.windowWidth < 640) {
        this.$refs.dialog.maximize()
      }
    },
    openModel() {
      this.$nuxt.$emit('toggleValuationCar')
    },
    openModel1() {
    },
    onHideDialog() {
      this.keyDialog++
    }
  }
}
