import config from '@/utils/config'
import {urlImgHandle} from "../utils";

const HOSTNAME = process.env.NUXT_ENV_WEBSITE_URL
export default {
  methods: {
    generateSEOData() {
      let robot = {hid: 'robots', name: 'robots', content: 'noindex,nofollow'}
      let robot2 = {hid: 'robots', name: 'robots', content: this.robotsContent}
      const meta = [
        {hid: 'og:title', property: 'og:title', content: this.pageTitle, once: true},
        {hid: 'description', name: 'description', content: this.description},
        {hid: 'og:image', property: 'og:image', content: this.image},
        {hid: 'og:image-secure_url', property: 'og:image:secure_url', content: this.image},
        {hid: 'og:image-alt', property: 'og:image:alt', content: this.pageTitle},
        {hid: 'og:description', property: 'og:description', content: this.description, once: true},
        {hid: 'og:url', property: 'og:url', content: HOSTNAME + this.$route.path},
      ]
      const link = [
        {
          rel: 'canonical',
          href: this.linkCanonical ? this.linkCanonical : HOSTNAME + this.$route.path
        }
      ]
      if (this.robotsContent) {
        meta.push(robot2)
      } else if (this.$route.fullPath.includes('?')) {
        meta.push(robot)
      }

      if ((this.seoUrl && this.seoUrl._id) || (this.seo && this.seo._id)) {
        return {
          title: `${this.pageTitle}`,
          meta,
          link
        }
      } else {
        return {
          meta,
          link
        }
      }
    }
  },
  head() {
    return this.generateSEOData()
  },
  async asyncData({route, app}) {
    const getScreenType = (path) => {
      switch (path) {
        case '/':
          return config.screenType.HOME_PAGE
        case '/mua-xe':
          return config.screenType.CAR_PURCHASE
        case '/ban-xe':
          return config.screenType.SELL_CAR
        case '/gioi-thieu':
          return config.screenType.INTRODUCE
        case '/tin-tuc':
          return config.screenType.NEWS_HOME_PAGE
        case '/xe-yeu-thich':
          return config.screenType.FAVORITE_CAR
        case '/tuyen-dung':
          return config.screenType.RECRUIT
      }
    }

    const screenType = getScreenType(route.path)
    console.log("screenType", screenType)
    try {
      const {data: seoUrl} = await app.$axios.get(`${config.api.seoUrl}?url=${route.path}`)
      if (seoUrl && seoUrl._id) {
        return {
          seoUrl,
          pageTitle: seoUrl.title,
          description: seoUrl.description,
          image: urlImgHandle(seoUrl.image, {w: 1200}),
          linkCanonical: seoUrl?.linkCanonical || '',
          robotsContent: seoUrl?.robotsContent || ''
        }
      } else {
        if (screenType) {
          try {
            const seo = await app.$axios.$get(`${config.api.seo}/${screenType}`)
            if (seo && seo._id) {
              const {attributes} = seo
              return {
                seo,
                pageTitle: attributes.title,
                description: attributes.description,
                image: urlImgHandle(attributes.imgCover, {w: 1200}),
                linkCanonical: seo?.linkCanonical || '',
                robotsContent: seo?.robotsContent || ''
              }
            }
          } catch (err) {
            return {
              seo: {},
              pageTitle: 'CARPLA - Nền tảng mua bán xe ô tô đã qua sử dụng lớn nhất Việt Nam',
              description: '',
              image: '',
              linkCanonical: '',
              robotsContent: ''
            }
          }
        }
      }
    } catch (err) {
      return {
        seo: {},
        pageTitle: 'CARPLA - Nền tảng mua bán xe ô tô đã qua sử dụng lớn nhất Việt Nam',
        description: '',
        image: '',
        linkCanonical: '',
        robotsContent: ''
      }
    }
  },
}
