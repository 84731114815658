export default {
  methods: {
    getHour(value) {
      return new Date(value * 1000).getHours()
    },
    getMinutes(value) {
      const minutes = new Date(value * 1000).getMinutes()
      if (minutes < 10) {
        return `0${minutes}`
      }
      return minutes
    },
  }
}
