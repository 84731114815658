

import config from "../utils/config";

export default {
  layout: "empty",
  head() {
    return {
      htmlAttrs: {
        lang: 'vi'
      },
      // link: [
      //   {
      //     rel: 'canonical',
      //     href: this.$route.path
      //   }
      // ],
      meta: [
        {hid: 'robots', name: 'robots', content: config.allowSEO ? 'index,follow' : "noindex,nofollow"}
      ]
    }
  },
}
