import platform from "platform";

export const getInfoTrackingDefault = () => {
  const OS = platform.os.family
  const device = platform.description
  const OS_version = platform.os.version
  return {
    OS, device, OS_version
  }
}
