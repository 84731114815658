
import ComingSoon from '@/pages/coming-soon'
import NewCategoryItem from "@/components/News/NewCategoryItem";
import {ACTION_GET_NEWS} from "@/utils/store.action";

export default {
  components: {NewCategoryItem, ComingSoon},
  head: {
    title: '404 Not Found ',
    meta: [
      { hid: 'robots', name: 'robots', content: 'noindex,nofollow' }
    ]
  },
  data() {
    return {
      allNewsByCategories: [],
      loading: true,
      comingsoonRoutes: [
        '/dich-vu/doi-xe',
        '/dich-vu/phu-kien',
        '/dich-vu/do-xe',
        '/dich-vu/car-spa',
        '/dich-vu/sua-chua-bao-duong',
        '/dich-vu/vay-mua-xe',
      ]
    }
  },
  async mounted() {
    try {
      const {data} = await this.$store.dispatch(ACTION_GET_NEWS)
      this.allNewsByCategories = data || []
      this.loading = false
    } catch (e) {
      this.loading = false
    }
  },
  methods: {
    goBack() {
      if (this.$route.path === '/') {
        process.client && window.location.reload()
      } else {
        this.$router.push('/')
      }
    },
    navigateToNewsPage() {
      this.$router.push("/tin-tuc")
    }
  }
}
