const axios = require("axios")
const LIST_REDIRECT_URL = process.env.NUXT_ENV_API_BASE + '/redirect'
const CryptoJS = require("crypto-js");
const cryptoSetting = {
  algorithm: 'aes-256-ctr',
  secretKey: process.env.CRYPTO_KEY || '8a20140f249eeb21befad80f74520243'
}
const decryptData = (response) => {
  if (response && response.data && response.data.d && response.data.c) {
    const {c, d} = response.data
    const {secretKey} = cryptoSetting
    const key = CryptoJS.enc.Hex.parse(secretKey)
    const iv = CryptoJS.enc.Hex.parse(c)
    const decrypted = CryptoJS.AES.decrypt(d.toString(), key, {
      mode: CryptoJS.mode.CTR,
      iv: iv,
      padding: CryptoJS.pad.NoPadding
    })
    let obj = {}
    try {
      obj = JSON.parse(decrypted.toString(CryptoJS.enc.Utf8))
    } catch (e) {
    }
    response.data = obj
  }
  return response
}

function checkIgnoreSEO(url) {
  const arr = ["/js", "/css", "/.nuxt", "_nuxt", "_loading"]
  const arrEndWiths = [".js", ".css"]
  for (let string of arr) {
    if (url.includes(string)) {
      return true
    }
  }
  for (let string of arrEndWiths) {
    if (url.endsWith(string)) {
      return true
    }
  }
  return false
}
export default async function (req, res, next) {
  if (checkIgnoreSEO(req.url)) {
    return next()
  }
  console.log("SEOO", req.url)
  try {
    const results = await axios.get(`${LIST_REDIRECT_URL}?oldUrl=${req.url}&active=1`, {
      headers: {
        'x-access-token': 'ssr'
      },
      params: {
        active: 1,
        perPage: 10
      },
      json: true
    })
    const {data: {data}} = decryptData(results)
    if (data.length) {
      const re = data[0]
      if (+re.type && re.newUrl) {
        res.writeHead(+re.type, {Location: re.newUrl})
        return res.end()
      }
    }
    return next()
  } catch (e) {
    console.error("Handle redirect err", e)
    next()
  }
}
