

import config from "../utils/config";
import Navbar from '~/layouts/Navbar'
import Footer from '@/layouts/Footer'
import ChatButton from '@/components/Elements/ChatButton'
import RudderStackHandler from '@/mixins/RudderStackHandler'
import DialogValuation from '@/components/Elements/DialogValuation'
import DialogSearchCarV2 from '@/components/Elements/DialogSearchCarV2'
import Popup from '@/components/Home/Popup'
import CleverTapHandler from "~/mixins/CleverTapHandler";
export default {
  name: "nofooter",
  mixins: [RudderStackHandler, CleverTapHandler],
  components: {Popup, DialogValuation, ChatButton, Footer, Navbar, DialogSearchCarV2},
  head() {
    return {
      htmlAttrs: {
        lang: 'vi'
      },
      // link: [
      //   {
      //     rel: 'canonical',
      //     href: this.$route.path
      //   }
      // ],
      meta: [
        {hid: 'robots', name: 'robots', content: config.allowSEO ? 'index,follow' : "noindex,nofollow"}
      ]
    }
  },
}
