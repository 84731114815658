import platform from 'platform'
import {getVerticalScrollPercentage} from "@/utils";
import {getInfoTrackingDefault} from "@/mixins/GetInfoTrackingDefault";

const CleverTapHandler = {
  data() {
    return {
      platform,
      rudderMetadata: {},
      percent: 0,
      maxPercent: 0
    }
  },
  created() {

  },
  watch: {
    percent: {
      handler: function (val) {
        this.maxPercent = this.maxPercent > val ? this.maxPercent : Math.floor(val)
      },
      deep: true,
      immediate: false
    },
  },
  mounted() {
    window.addEventListener('scroll', () => {
      this.percent = getVerticalScrollPercentage(document.body)
    },true)
  },
  methods: {
    updateProfile(userInfo = {}) {
      clevertap.profile?.push({Site: {...userInfo}});
    },
    identifyUser(userId, userInfo = {}) {
      clevertap.onUserLogin?.push({Site: {Identity: userId, ...userInfo}});
      console.log('clv id', clevertap.getCleverTapID())
    },
    clevertapTrack(type, data = {}) {
      const infoDefault = getInfoTrackingDefault()
      // console.log('clevertap',type, data)
      const props = {
        ...data,
        ...infoDefault,
        time_stamp: this.$moment().unix(),
        scroll_level: this.percent,
        url: window.location.href.toString()
      }

      clevertap.event.push(type, props)

      // rudderstack
      // if(rudderanalytics) {
      //   rudderanalytics.track(type, props)
      // }
    }
  }
}

export default CleverTapHandler
