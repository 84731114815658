

import {ACTION_SIGN_IN_WITH_VETC} from "@/utils/store.action";
import UrlImageHandler from "@/mixins/UrlImageHandler";
import config from "@/utils/config";
import platform from "platform";
import RudderStackHandler from "@/mixins/RudderStackHandler";
import CleverTapHandler from "@/mixins/CleverTapHandler";
import eventEmitter from "@/utils/eventEmitter";
import SeoHandler from "../../../mixins/SeoHandler";

export default {
  layout: "empty",
  mixins: [UrlImageHandler, RudderStackHandler, CleverTapHandler, SeoHandler],
  data() {
    return {
      session_state: this.$route.query.session_state || '',
      code: this.$route.query.code || '',
      loading: false,
      isOauth: true
    }
  },
  mounted() {
    this.rudderPage()
    this.clevertapTrack(config.eventTypeTracking.page_view, {page: window.location.href})
    if (this.code) {
      this.loginVETC()
    } else {
      this.$toasted.clear()
      this.$toasted.show('Có lỗi xảy ra trong quá trình đăng nhập. Vui lòng thử lại', {
        type: 'error',
        duration: 3000,
        position: 'top-center'
      })
      this.$router.push('/dang-nhap')
    }
  },
  methods: {
    async loginVETC() {
      try {
        const {user, uid} = await this.$store.dispatch(ACTION_SIGN_IN_WITH_VETC, {
          code: this.code,
          domain: window.location.origin + window.location.pathname
        })
        this.identifyUser(uid, {
          Name: user.name,
          Email: user.email,
          Phone: user.phoneNumber
        })
        this.updateProfile({
          forum_user_id: user?.forumUserId,
          carpla_user_id: uid,
          app_user_id: '',
          account_number: user?.vetcInfo?.account_number || ''
        })

        const isOauth = localStorage.getItem('isOauth')
        const from = localStorage.getItem('fromSource')
        if (isOauth) localStorage.removeItem('isOauth')
        if (from) {
          localStorage.removeItem('from')
          localStorage.removeItem('fromSource')
        }
        if (user) {
          this.rudderTrack(config.newEventName.login_clicked, {
            method: 'vetc',
            status: 'pass',
            email: user?.email || '',
            name: user.fullName,
            phone: user.phoneNumber,
            user_name: user.username,
            gender: user.gender,
            userID: user.uid,
            forumUserID: user.forumUserId,
            account_number: user?.vetcInfo
          })
          this.clevertapTrack(config.eventTypeTracking.log_in_clicked, {
            button_clicked: 'Click button login with vetc',
            screen_name: 'Login',
            user_name: user.uid,
            sign_in_method: 'VETC'
          })
          if (!Boolean(isOauth)) {
            if (from) {
              window.location.href = from
            } else {
              await this.$router.push('/')
            }
          } else {
            const client_id = localStorage.getItem('client_id')
            const grant_type = localStorage.getItem('grant_type')
            const state = localStorage.getItem('state')
            const response_type = localStorage.getItem('response_type')
            const redirect_uri = localStorage.getItem('redirect_uri')
            if (client_id) localStorage.removeItem('client_id')
            if (grant_type) localStorage.removeItem('grant_type')
            if (state) localStorage.removeItem('state')
            if (response_type) localStorage.removeItem('response_type')
            if (redirect_uri) localStorage.removeItem('redirect_uri')
            this.$router.push('/oauth?client_id=' + client_id + '&grant_type=' + grant_type + '&state=' + state + '&response_type=' + response_type + '&redirect_uri=' + redirect_uri + '&from=' + from)
          }
        }
      } catch (err) {
        const status = err?.response?.status
        if (status && status !== config.httpCode.OK) {
          this.$toasted.clear()
          this.$toasted.show('Có lỗi xảy ra trong quá trình đăng nhập. Vui lòng thử lại', {
            type: 'error',
            duration: 3000,
            position: 'top-center'
          })
          this.$router.push('/dang-nhap')
        }
      }
    }
  }
}
