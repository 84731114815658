
import {CollapseTransition} from "@ivanv/vue-collapse-transition"

export default {
  components: {
    CollapseTransition
  },
  props: {
    title: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isOpenA: false
    }
  }
}
