import config from '@/utils/config'
import {urlImgHandle} from "../utils";

const UrlImageHandler = {
  methods: {
    urlImg(path, imgOption = {}) {
      return urlImgHandle(path,imgOption)
    }
  }
}

export default UrlImageHandler
