// import mqtt from 'mqtt/dist/mqtt.min'
import config from "@/utils/config";
import eventEmitter from "@/utils/eventEmitter";

export default {
  data() {
    return {
      connection: {
        host: process.env.NUXT_ENV_MQTT_HOST,
        endpoint: '/mqtt',
        clean: false, // Reserved session
        connectTimeout: 4000, // Time out
        reconnectPeriod: 1000, // Reconnection interval
        rejectUnauthorized: false,
        // Certification Information
        clientId: 'carpla_' + Math.random().toString(16).substring(2, 8),
        username: 'emqx_test',
        password: 'emqx_test',
      },
      subscription: {
        topic: 'topic/mqttx',
        qos: 1,
      },
      publish: {
        topic: 'topic/browser',
        qos: 1,
        payload: '{ "msg": "Hello, I am browser." }',
      },
      receiveNews: '',
      qosList: [
        {label: 0, value: 0},
        {label: 1, value: 1},
        {label: 2, value: 2},
      ],
      client: {
        connected: false,
      },
      subscribeSuccess: false,
    }
  },
  async mounted() {
    // await this.getMqttInfo()
    // await this.createConnection()
  },
  methods: {
    async getMqttInfo() {
      const data = await this.$axios.$post(`${config.api.base}/mqtt/token`)
      const { token, topic, username } = data || {}
      this.connection.username = username
      this.connection.password = token
      this.subscription.topic = (topic || [])[0]
    },
    createConnection() {
      if(process.client){
        const {host, port, endpoint, ...options} = this.connection
        const connectUrl = `${host}${endpoint}`
        try {
          this.client = mqtt.connect(connectUrl, options)
        } catch (error) {
          console.log('mqtt.connect error', error)
        }
        this.client.on('connect', () => {
          console.log('Connection succeeded!')
          this.doSubscribe()
        })
        this.client.on('error', error => {
          console.log('Connection failed', error)
        })
        this.client.on('message', (topic, message) => {
          const messageObj = message ? JSON.parse(message) : {}
          const { payload, template } = messageObj
          console.log('message mqtt --- ', messageObj)
          eventEmitter.emit('messageMqtt', messageObj)
          this.receiveNews = this.receiveNews.concat(message)
        })
      }
    },

    doSubscribe() {
      const { topic, qos } = this.subscription
      this.client.subscribe(topic, { qos }, (error, res) => {
        if (error) {
          console.log('Subscribe to topics error', error)
          return
        }
        this.subscribeSuccess = true
        console.log('Subscribe to topics')
      })
    },

    destroyConnection() {
      if (this.client.connected) {
        try {
          this.client.end()
          this.client = {
            connected: false,
          }
          console.log('Successfully disconnected!')
        } catch (error) {
          console.log('Disconnect failed', error.toString())
        }
      }
    }
  }
}
