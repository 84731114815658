
import {ACTION_GET_CAR_ATTRIBUTES} from "../utils/store.action";

export default {
  name: 'MoreFooterPartItem',
  props: {
    isHome: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data() {
    return {
      manufacturers: []
    }
  },
  created() {
    this.getCarAttributesibutes()
  },
  methods: {
    async getCarAttributesibutes() {
      if (this.isHome) {
        const res = await this.$store.dispatch(ACTION_GET_CAR_ATTRIBUTES)
        const {manufacturers} = res || {}
        if (manufacturers && manufacturers.length) {
          this.manufacturers = manufacturers.sort((a, b) => b.count - a.count)
        }
      }
    },
  }
}
