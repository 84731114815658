const ResizeHandler = {
  data() {
    return {
      windowWidth: process.client ? window.innerWidth : '',
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize,true);
    })
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
}

export default ResizeHandler
