import {VISITOR_ID} from '@/utils/constants'
import platform from 'platform'
import {getVerticalScrollPercentage} from "@/utils";
import eventEmitter from "../utils/eventEmitter";

const RudderStackHandler = {
  data() {
    return {
      platform,
      device: {
        name: platform?.name,
        version: platform?.version,
        os: platform?.os
      }
    }
  },
  computed: {
    userInfo() {
      return this.$store.getters['authen/userInfo'] || {}
    }
  },
  mounted() {
    let oldPercent = 0
    document.onscroll = () => {
      this.percent = getVerticalScrollPercentage(document.body)
      const value = +this.percent.toFixed(0)
      if (value) {
        if (value >= 25 && value < 50) {
          if (oldPercent < 25) {
            oldPercent = 25
            this.rudderTrackScroll(25)
          }
        } else if (value >= 50 && value < 75) {
          if (oldPercent < 50) {
            oldPercent = 50
            this.rudderTrackScroll(50)
          }
        } else if (value >= 75 && value < 100) {
          if (oldPercent < 75) {
            oldPercent = 75
            this.rudderTrackScroll(75)
          }
        } else if (value === 100 && oldPercent !== 100) {
          oldPercent = 100
          this.rudderTrackScroll(100)
        }
      }
    }
  },
  methods: {
    // handleIdentify() {
    //   const userId = this.userInfo?._id
    //   const phone = this.userInfo?.phoneNumber
    //   const visitorId = this.$cookies.get(VISITOR_ID)
    //   const userInfo = {userId, visitorId, phone}
    //   if (rudderanalytics && typeof rudderanalytics === 'object') {
    //     rudderanalytics?.identify('userId', userInfo, () => {
    //       return userInfo
    //     })
    //   }
    // },

    handleIdentify() {
      const userID = this.userInfo?.uid
      const visitorID = this.$cookies.get(VISITOR_ID) || localStorage.getItem("visitorIdCarpla")
      const sessionId = localStorage.getItem('sessionUserId') || ''
      return {userID, visitorID, anonymousId: visitorID, sessionId}
    },
    rudderTrack(eventName, data = {}) {
      eventEmitter.emit('workerCheckEmit')
      try {
        const rudderanalytics = window.rudderanalytics
        if (rudderanalytics && typeof rudderanalytics === 'object') {
          const userInfo = this.handleIdentify()
          const time = new Date()
          const dt = {
            sentAt: time.toISOString(),
            originalTimestamp: time.toISOString(),
            ...userInfo,
            device: this.device,
            type: 'track',
            ...data
          }
          rudderanalytics?.track(
            eventName,
            dt,
            () => {
              // console.log('track event')
            }
          )
        }
      } catch (e) {

      }
    },
    rudderTrackScroll(val) {
      eventEmitter.emit('workerCheckEmit')
      if (val > 0) {
        try {
          const rudderanalytics = window.rudderanalytics
          if (rudderanalytics && typeof rudderanalytics === 'object') {
            const userInfo = this.handleIdentify()
            const time = new Date()
            rudderanalytics?.track(
              'scroll',
              {
                sentAt: time.toISOString(),
                originalTimestamp: time.toISOString(),
                ...userInfo,
                type: 'track',
                scroll_depth: val,
                device: this.device,
                page_url: window.location.href
              },
              () => {
                // console.log('track event')
              }
            )
          }
        } catch (e) {
          console.log('tracking scrooll fail', e)
        }
      }
    },
    rudderPage() {
      eventEmitter.emit('workerCheckEmit')
      try {
        const params = Object.fromEntries(new URLSearchParams(location.search));
        const rudderanalytics = window.rudderanalytics
        if (rudderanalytics && typeof rudderanalytics === 'object') {
          const userInfo = this.handleIdentify()
          const time = new Date()
          const dt = {
            sentAt: time.toISOString(),
            originalTimestamp: time.toISOString(),
            ...userInfo,
            type: 'page'
          }
          rudderanalytics?.page({
            ...params,
            page: 'page_view',
            page_url: window.location.href,
            device: this.device,
            path: this.$route.path,
            page_title: this.$route.name !== 'index' ? this.$route.name : 'Trang chủ',
            ...dt,
          })
        }
      } catch (e) {

      }
    },
    rudderPageCustom(data) {
      eventEmitter.emit('workerCheckEmit')
      try {
        const params = Object.fromEntries(new URLSearchParams(location.search));
        const rudderanalytics = window.rudderanalytics
        if (rudderanalytics && typeof rudderanalytics === 'object') {
          const userInfo = this.handleIdentify()
          const time = new Date()
          const dt = {
            sentAt: time.toISOString(),
            originalTimestamp: time.toISOString(),
            ...userInfo,
            type: 'page'
          }
          rudderanalytics?.page({
            page: 'page_view',
            device: this.device,
            ...dt,
            ...data,
            ...params,
          })
        }
      } catch (e) {

      }
    },
  }
}

export default RudderStackHandler
