
import Navbar from '~/layouts/Navbar'
import Footer from '@/layouts/Footer'
import ChatButton from '@/components/Elements/ChatButton'
import RudderStackHandler from '@/mixins/RudderStackHandler'
import config from '@/utils/config'
import DialogValuation from '@/components/Elements/DialogValuation'
import DialogSearchCarV2 from '@/components/Elements/DialogSearchCarV2'
import {ACTION_SET_TOKEN_NOTIFY, ACTION_SET_TRACKING_CODE} from "@/utils/store.action";
import Popup from '@/components/Home/Popup'
import eventEmitter from "~/utils/eventEmitter";
import CleverTapHandler from "~/mixins/CleverTapHandler";
import MqttHandler from "@/mixins/MqttHandler";

export default {
  mixins: [RudderStackHandler, CleverTapHandler, MqttHandler],
  components: { Popup, DialogValuation, ChatButton, Footer, Navbar, DialogSearchCarV2},
  head() {
    return {
      htmlAttrs: {
        lang: 'vi'
      },
      // link: [
      //   {
      //     rel: 'canonical',
      //     href: this.$route.path
      //   }
      // ],
      meta: [
        {hid: 'robots', name: 'robots', content: config.allowSEO ? 'index,follow' : "noindex,nofollow"}
      ]
    }
  },
  data() {
    return {
      showPopup: false,
      templatePopup: '',
      intervalPing: null,
      fabOptions: {
        spacing: 60,
        delay: 0.08
      }
    }
  },
  computed: {
    userInfo() {
      return this.$store.getters['authen/userInfo']
    }
  },
  mounted() {

    eventEmitter.on('messageMqtt', message => {
      const { payload, template } = message || {}
      this.getPopup(payload)
    })

    eventEmitter.removeAllListeners("identifyUser")
    eventEmitter.on('identifyUser', data => {
      const {uid, user} = data || {}
      this.identifyUser(uid, {
        Name: user.name,
        Email: user.email,
        Phone: user.phoneNumber
      })
      this.updateProfile({
        forum_user_id: user?.forumUserId,
        carpla_user_id: uid,
        app_user_id: '',
        account_number: user?.vetcInfo?.account_number || ''
      })
    })

    //tracking-code
    const tracking_code_store = this.$store.getters['tracking/tracking_code']

    const {tracking_code} = this.$route.query

    if (tracking_code && tracking_code_store.code !== tracking_code) {
      const createdAt = Date.now()
      this.$store.dispatch(ACTION_SET_TRACKING_CODE, {code: tracking_code, createdAt})
    }

    if (process.client) {
      document.body.scrollIntoView({
        behavior: 'smooth'
      })
    }

  },
  methods: {
    async getPopup(payload = {}) {
      try {
        const data = await this.$axios.$get(`${config.api.base}/component/${payload.popupName}`)
        const { component } = data || {}
        const pattern = /\$\w+/gi
        const arr = component?.template?.match(pattern)

        for (let field of arr) {
          const key = field.replace("$","")
          const val = payload.data[key]
          component.template = component?.template?.replace(field, val||'')
        }
        this.templatePopup = component.template
        this.$nuxt.$emit('showPopupAds', this.templatePopup)
      } catch (err) {

      }
    },
    clickCall() {
      window.open('tel:0825355355', '_self');
    },
    clickZalo() {
      window.open('https://zalo.me/3778111172682040948', '_blank');
    },
    clickMessenger() {
      window.open('https://facebook.com/carplavietnam', '_blank');
    },
    async startListeners() {
      await this.requestPermission();
      await this.getIdToken();
    },
    async requestPermission() {
      try {
        await Notification.requestPermission();
      } catch (e) {
        // console.error("Error : ", e);
      }
    },
    async getIdToken() {
      try {
        const idToken = await this.$fire.messaging.getToken();
        if (idToken) {
          const data = {fcmToken: idToken}
          await this.$store.dispatch(ACTION_SET_TOKEN_NOTIFY, data)
        }
      } catch (e) {
        // console.error("Error : ", e);
      }
    },
  }
}
