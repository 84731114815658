
import LanguageBox from '@/components/Elements/LanguageBox'
import {ACTION_GET_INFO_CARPLA, ACTION_GET_MANUFACTURERS, ACTION_LOGOUT} from '@/utils/store.action'
import {USER_KEY} from '@/utils/constants'
import Tooltip from "@/components/Elements/Tooltip";
import config from '@/utils/config'
import UrlImageHandler from '@/mixins/UrlImageHandler'
import RudderStackHandler from '@/mixins/RudderStackHandler'
import Notification from "@/components/Notification/Notification";
import Logo from "../components/Logo"
import SignInDialog from "../components/Dialog/SignInDialog.vue";
import LoginComponents from "../components/HomeNew/LoginComponents.vue";

export default {
  components: {LoginComponents, SignInDialog, Notification, LanguageBox, Tooltip, Logo},
  mixins: [UrlImageHandler, RudderStackHandler],
  data() {
    return {
      aboutCollapsed: true,
      serviceCollapsed: true,
      sellcarCollapsed: true,
      newsCollapsed: true,
      buyCarCollapsed: true,
      slug: this.$route.params?.slug,
      username: '',
      avatar: null,
      carplaSDT: '0825 355 355',
      carplaSDTMuaXe: '0877 999 888',
      linkSDT: 'tel:0825355355',
      linkSDTMuaXe: 'tel:0877999888',
      visibleLeftSidebar: false,
      visibleRightSidebar: false,
      visibleBottomSidebar: false,
      searchStr: '',
      manufacturers: [],
      services: [
        // {name: 'Đổi xe', route: '/dich-vu/doi-xe'},
        // {name: 'Phụ kiện', route: '/dich-vu/phu-kien'},
        // {name: 'Độ xe', route: '/dich-vu/do-xe'},
        // {name: 'Car spa', route: '/dich-vu/car-spa'},
        // {name: 'Sửa chữa, bảo dưỡng', route: '/dich-vu/sua-chua-bao-duong'},
        // {name: 'Vay mua xe', route: '/dich-vu/vay-mua-xe'},
        {name: 'Bảo hiểm', route: 'https://baohiemtasco.vn'},
        {name: 'Cứu hộ RSA', route: '/dich-vu/cuu-ho-rsa'},
      ],
      buyCarRoutes: ['/mua-xe'],
      sellCarRoutes: ['/ban-xe'],
      serviceRoutes: ['/dich-vu', '/dich-vu/cuu-ho-rsa'],
      moreRoutes: ['/'],
      aboutRoutes: ['/gioi-thieu', '/chung-nhan-carpla', '/faq', '/tuyen-dung', '/tin-tuc'],
      newsRoutes: ['']
    }
  },
  watch: {
    userInfo: {
      handler: function (userInfo = {}) {
        if (!userInfo) {
          this.avatar = undefined
        }
        if (userInfo && !userInfo._id) {
          this.avatar = undefined
        }
        if (userInfo && userInfo.avatar && userInfo.avatar.length) {
          this.avatar = this.urlImg(userInfo.avatar, {w: 96, h: 96})
        }
        this.username = userInfo.name || `${userInfo.first_name || ''} ${userInfo.last_name || ''}` || userInfo.fullname || ''
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    userInfo() {
      return this.$store.getters['authen/userInfo'] || {}
    },
    loggedIn() {
      return this.$store.getters['authen/token'] && !this.$store.getters['authen/isGuest']
    },
    filterProps() {
      return this.$store.getters['cars/filterProps']
    },
    manuList() {
      return this.$store.getters['manufacturers/manufacturers']
    }
  },
  async created() {
    try {
      const data = await this.$store.dispatch(ACTION_GET_MANUFACTURERS, {perPage: 1000})
      // const res = await this.$store.dispatch(ACTION_GET_INFO_CARPLA)
      // if (res && res.hcm) {
      //   this.carplaSDT = res.hcm.hotline
      //   this.linkSDT = "tel:" + res.hcm.hotline
      // }
      const arr = (data || []).map(item => {
        const {logo, name, _id: id} = item
        return {
          id,
          img: logo,
          name,

          // route: `/mua-xe?manufacturer=${id}`,
          route: `/mua-xe/${item.slug || item.name}`,
        }
      })
      this.manufacturers = [...arr]
    } catch (e) {

    }
  },
  async mounted() {
    this.$nuxt.$on('updateAvatar', avatar => {
      this.avatar = avatar
    })
    this.$nuxt.$on('updateUsername', name => {
      this.username = name
    })
  },
  methods: {
    handleClickLogin() {
      if (!this.loggedIn) {
        // this.$router.push('/dang-nhap')
        this.$refs.signInDialog.toggleDialog()
      }
    },
    toggleValuationCar() {
      this.visibleLeftSidebar = false
      this.$nuxt.$emit('toggleValuationCar')
    },
    clickLinkTracker() {
      this.rudderTrack(config.newEventName.car_compare, {
        page_url: window.location.href,
        product_id: '',
        button: 'compare',
        position: 'header'
      })
    },
    goToManufacturerDetails(item) {
      const obj = (this.manuList || []).find(i => i._id === item.id) || {}
      if (item.id) {
        const filterProps = this.filterProps ? JSON.parse(JSON.stringify(this.filterProps)) : {}
        filterProps.manufacturer = obj._id ? [obj] : []
        this.$store.dispatch('cars/setFilterProps', filterProps)
        setTimeout(() => {
          const element = document.getElementById("car_list_banner");
          if (element) {
            element?.scrollIntoView({
              behavior: 'smooth'
            });
          }
        }, 100)
      } else {
        this.$store.dispatch('cars/setFilterProps', {})
      }
    },
    clickHotline(value) {
      this.rudderTrack(config.newEventName.contact_link_clicked, {
        type: 'phone',
        value: value.replace('tel:', ''),
        page_url: window.location.href,
        position: 'header'
      })
    },
    toggleLeftSidebar(isCarplaBlog) {
      if (isCarplaBlog) {
        this.rudderPageCustom({
          page_url: 'https://carpla.vn/blog',
          path: '/blog',
          page_title: 'Carpla Blog',
        })
      }
      if (this.visibleBottomSidebar) this.visibleBottomSidebar = false
      this.visibleLeftSidebar = !this.visibleLeftSidebar
      this.visibleRightSidebar = false
    },
    toggleSearchCarV2Mobile() {
      this.visibleLeftSidebar = !this.visibleLeftSidebar
      this.visibleRightSidebar = false
      this.toggleSearchCarV2()
    },
    toggleRightSidebar() {
      this.visibleRightSidebar = !this.visibleRightSidebar
      this.visibleLeftSidebar = false
    },
    toggleLogo() {
    },
    appDownload(app_name, value) {
      this.clevertapTrack(config.eventTypeTracking.button_downloadapp_clicked, {
        app_id: app_name,
        button_clicked: 'Click download app',
        screen_name: 'navbar'
      })
      this.rudderTrack(config.newEventName.outbound_link_clicked, {
        domain: value,
        value,
        page_url: window.location.href,
      })
    },
    clickLinkTrackCarplaBH() {
      this.rudderTrack(config.newEventName.outbound_link_clicked, {
        domain: 'https://baohiemtasco.com/san-pham/bao-hiem-o-to-xe-may',
        value: 'https://baohiemtasco.com/san-pham/bao-hiem-o-to-xe-may',
        page_url: window.location.href,
      })
      this.rudderPageCustom({
        page_url: 'https://baohiemtasco.com/san-pham/bao-hiem-o-to-xe-may',
        path: '/bao-hiem-xe',
        page_title: 'Carpla Bảo Hiểm',
      })
    },
    isRouteActive(route) {
      if (typeof route === 'string') {
        return route === this.$route.path
      } else if (Array.isArray(route)) {
        return route.includes(this.$route.path)
      }
    },
    toggleSearchCarV2() {
      this.$nuxt.$emit('toggleSearchCarV2')
    },
    async logout(isMobile) {
      this.avatar = undefined
      this.visibleRightSidebar = false
      await this.$store.dispatch(ACTION_LOGOUT)
      // const query = localStorage.getItem("query")
      // location.replace(`/dang-nhap${query || ''}`)
      localStorage.removeItem("query")
      // this.$nuxt.$emit('mergeGuest')
    }
  }
}
